import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AdminHeader from "./AdminHeader";
import { server } from "../../server";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import countryList from "react-select-country-list";

const generateReceiptNumber = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let receipt = "";
  for (let i = 0; i < 14; i++) {
    receipt += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return receipt;
};

const AddCargo = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    userId: "",
    receiptNo: generateReceiptNumber(),
    email: "",
    phone: "",
    shipFrom: "",
    quantitynquality: "",
    amount: "",
    shippingMode: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${server}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          email: response.data.email || "",
          phone: response.data.phone || "",
          userId: response.data._id || "",
        }));
      } catch (error) {
        console.error("Error fetching cargo details:", error);
        toast.error("Failed to fetch cargo details. Please try again.");
      }
    };
    fetchUserDetails();
  }, [id]);

  const navigate = useNavigate();
  const countryOptions = countryList().getData();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (value, action) => {
    setFormData({ ...formData, [action.name]: value.label });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const key in formData) {
      if (formData[key].trim() === "") {
        toast.error(`${key} is required.`);
        return;
      }
    }

    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error("Please Login Again");
        throw new Error("Authentication token not found");
      }
      setLoading(true);
      const response = await fetch(`${server}/cargos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      await axios.post(`${server}/usernotifications`, {
        userId: id,
        title: "New Cargo Added",
        message: `A new cargo was added for you.`,
      });
      if (response.ok) {
        setLoading(false);
        toast.success("Cargo Added Successfully");
        navigate("/cargo");
      }
      if (!response.ok) {
        setLoading(false);
        if (response.status === 400) {
          toast.error("Cargo with this receipt number already exists");
        } else {
          toast.error("Something went Wrong");
        }
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      console.log("Cargo added successfully:", data);
      setFormData({
        userId: "",
        receiptNo: "",
        email: "",
        phone: "",
        shipFrom: "",
        quantitynquality: "",
        amount: "",
        shippingMode: "",
      });
    } catch (error) {
      setLoading(false);
      console.error("Error adding cargo:", error);
      // toast.error("Failed to add cargo. Please try again later.");
    }
  };

  return (
    <div>
      <AdminHeader />
      <div className="contacts__section">
        <section className="contact" id="contact">
          <div className="container">
            <h2 className="section__title " style={{ marginTop: "10px" }}>
              Add A Cargo
            </h2>
            <p className="section-text" style={{ textAlign: "center" }}>
              Fill All Details To Add A Cargo
            </p>

            <div className="contact-wrapper">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="receiptNo">Receipt Number*</label>
                    <input
                      type="text"
                      name="receiptNo"
                      id="receiptNo"
                      value={formData.receiptNo}
                      onChange={handleChange}
                      placeholder="Enter Receipt Number"
                      className="input-field"
                      disabled
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="email">Customer email*</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter Customer email"
                      className="input-field"
                      style={{ background: "#fff" }}
                    />
                  </div>
                </div>

                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="phone">Client Phone Number*</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter Customer Phone Number"
                      className="input-field"
                      style={{ background: "#fff" }}
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="shipFrom">Ship From*</label>
                    <Select
                      name="shipFrom"
                      id="shipFrom"
                      options={countryOptions}
                      onChange={handleSelectChange}
                      className="select-field"
                      style={{ background: "#fff" }}
                    />
                  </div>
                </div>
                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="amount">Cargo Amount*</label>
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      placeholder="Enter Cargo Amount"
                      className="input-field"
                      style={{ background: "#fff" }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="shippingMode">Shipping Mode*</label>
                    <select
                      name="shippingMode"
                      id="shippingMode"
                      value={formData.shippingMode}
                      onChange={handleChange}
                      className="input-field"
                      style={{ background: "#fff", cursor: "pointer" }}
                    >
                      <option value="">Select Shipping Mode</option>
                      <option value="Air">Air</option>
                      <option value="Sea">Sea</option>
                      <option value="Land">Land</option>
                    </select>
                  </div>
                </div>
                <div className="input-wrapper">
                  <label htmlFor="quantitynquality">
                    Quantity and Quality*
                  </label>
                  <textarea
                    name="quantitynquality"
                    id="quantitynquality"
                    value={formData.quantitynquality}
                    onChange={handleChange}
                    placeholder="Enter Quantity and Quality"
                    className="input-field"
                    style={{ background: "#fff" }}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    border: "none",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                  }}
                >
                  <span>{loading ? "Adding .." : "Add a Cargo"}</span>
                  <i
                    className="bx bxl-telegram"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
              </form>
              <figure class="service-banner">
                <figure class="service-banner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    width="454"
                    height="225"
                    viewBox="0 0 782.04441 701.88002"
                    style={{ width: "100%" }}
                  >
                    <path
                      d="M609.48783,100.59015l-25.44631,6.56209L270.53735,187.9987,245.091,194.56079A48.17927,48.17927,0,0,0,210.508,253.17865L320.849,681.05606a48.17924,48.17924,0,0,0,58.61776,34.58317l.06572-.01695,364.26536-93.93675.06572-.01695a48.17923,48.17923,0,0,0,34.58309-58.6178l-110.341-427.87741A48.17928,48.17928,0,0,0,609.48783,100.59015Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M612.94784,114.00532l-30.13945,7.77236L278.68955,200.20385l-30.139,7.77223a34.30949,34.30949,0,0,0-24.6275,41.74308l110.341,427.87741a34.30946,34.30946,0,0,0,41.7431,24.62736l.06572-.01695,364.26536-93.93674.06619-.01707a34.30935,34.30935,0,0,0,24.627-41.7429l-110.341-427.87741A34.30938,34.30938,0,0,0,612.94784,114.00532Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#fff"
                    />
                    <path
                      d="M590.19,252.56327,405.917,300.08359a8.01411,8.01411,0,0,1-4.00241-15.52046l184.273-47.52033A8.01412,8.01412,0,0,1,590.19,252.56327Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M628.955,270.49906,412.671,326.27437a8.01411,8.01411,0,1,1-4.00241-15.52046l216.284-55.77531a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M620.45825,369.93676l-184.273,47.52032a8.01411,8.01411,0,1,1-4.00242-15.52046l184.273-47.52032a8.01411,8.01411,0,1,1,4.00241,15.52046Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M659.22329,387.87255l-216.284,55.77531a8.01411,8.01411,0,1,1-4.00242-15.52046l216.284-55.77531a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M650.72653,487.31025l-184.273,47.52033a8.01412,8.01412,0,0,1-4.00242-15.52047l184.273-47.52032a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M689.49156,505.246l-216.284,55.77532a8.01412,8.01412,0,1,1-4.00241-15.52047l216.284-55.77531a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M374.45884,348.80871l-65.21246,16.817a3.847,3.847,0,0,1-4.68062-2.76146L289.5963,304.81607a3.847,3.847,0,0,1,2.76145-4.68061l65.21247-16.817a3.847,3.847,0,0,1,4.68061,2.76145l14.96947,58.04817A3.847,3.847,0,0,1,374.45884,348.80871Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M404.72712,466.1822l-65.21247,16.817a3.847,3.847,0,0,1-4.68062-2.76146l-14.96946-58.04816A3.847,3.847,0,0,1,322.626,417.509l65.21246-16.817a3.847,3.847,0,0,1,4.68062,2.76145l14.96946,58.04817A3.847,3.847,0,0,1,404.72712,466.1822Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M434.99539,583.55569l-65.21246,16.817a3.847,3.847,0,0,1-4.68062-2.76145l-14.96946-58.04817a3.847,3.847,0,0,1,2.76145-4.68062l65.21247-16.817a3.847,3.847,0,0,1,4.68061,2.76146l14.96947,58.04816A3.847,3.847,0,0,1,434.99539,583.55569Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M863.63647,209.0517H487.31811a48.17928,48.17928,0,0,0-48.125,48.12512V699.05261a48.17924,48.17924,0,0,0,48.125,48.12507H863.63647a48.17924,48.17924,0,0,0,48.125-48.12507V257.17682A48.17928,48.17928,0,0,0,863.63647,209.0517Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M863.637,222.90589H487.31811a34.30948,34.30948,0,0,0-34.271,34.27093V699.05261a34.30947,34.30947,0,0,0,34.271,34.27088H863.637a34.30936,34.30936,0,0,0,34.27051-34.27088V257.17682A34.30937,34.30937,0,0,0,863.637,222.90589Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#fff"
                    />
                    <circle
                      cx="694.19401"
                      cy="614.02963"
                      r="87.85039"
                      fill="#6c63ff"
                    />
                    <path
                      d="M945.18722,701.63087H914.63056V671.07421a11.45875,11.45875,0,0,0-22.9175,0v30.55666H861.1564a11.45875,11.45875,0,0,0,0,22.9175h30.55666V755.105a11.45875,11.45875,0,1,0,22.9175,0V724.54837h30.55666a11.45875,11.45875,0,0,0,0-22.9175Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#fff"
                    />
                    <path
                      d="M807.00068,465.71551H616.699a8.01412,8.01412,0,1,1,0-16.02823H807.00068a8.01412,8.01412,0,0,1,0,16.02823Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M840.05889,492.76314H616.699a8.01412,8.01412,0,1,1,0-16.02823H840.05889a8.01411,8.01411,0,1,1,0,16.02823Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M807.00068,586.929H616.699a8.01412,8.01412,0,1,1,0-16.02823H807.00068a8.01411,8.01411,0,0,1,0,16.02823Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M840.05889,613.97661H616.699a8.01412,8.01412,0,1,1,0-16.02823H840.05889a8.01412,8.01412,0,1,1,0,16.02823Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M574.07028,505.04162H506.72434a3.847,3.847,0,0,1-3.84278-3.84278V441.25158a3.847,3.847,0,0,1,3.84278-3.84278h67.34594a3.847,3.847,0,0,1,3.84278,3.84278v59.94726A3.847,3.847,0,0,1,574.07028,505.04162Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M574.07028,626.25509H506.72434a3.847,3.847,0,0,1-3.84278-3.84278V562.46505a3.847,3.847,0,0,1,3.84278-3.84278h67.34594a3.847,3.847,0,0,1,3.84278,3.84278v59.94726A3.847,3.847,0,0,1,574.07028,626.25509Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#e6e6e6"
                    />
                    <path
                      d="M807.21185,330.781H666.91017a8.01411,8.01411,0,0,1,0-16.02823H807.21185a8.01411,8.01411,0,0,1,0,16.02823Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#ccc"
                    />
                    <path
                      d="M840.27007,357.82862H666.91017a8.01411,8.01411,0,1,1,0-16.02822h173.3599a8.01411,8.01411,0,0,1,0,16.02822Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#ccc"
                    />
                    <path
                      d="M635.85911,390.6071H506.51316a3.847,3.847,0,0,1-3.84277-3.84277V285.81706a3.847,3.847,0,0,1,3.84277-3.84277H635.85911a3.847,3.847,0,0,1,3.84277,3.84277V386.76433A3.847,3.847,0,0,1,635.85911,390.6071Z"
                      transform="translate(-208.9778 -99.05999)"
                      fill="#6c63ff"
                    />
                  </svg>
                </figure>
              </figure>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddCargo;
