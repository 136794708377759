import React, { useEffect, useState } from "react";
import "./admin.css";
import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import { server } from "../../server";

const AdminHome = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuVisible2, setMenuVisible2] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const toggleMenu2 = () => {
    setMenuVisible2(!menuVisible2);
  };

  const [decodedToken, setDecodedToken] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        setDecodedToken(decoded);
      }
    } else {
      setDecodedToken(null);
    }
  }, []);

  const [cargos, setCargos] = useState([]);
  useEffect(() => {
    fetchCargos();
  }, []);

  const fetchCargos = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    try {
      setLoading(true);
      const response = await fetch(`${server}/cargos`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoading(false);
        toast.error("Error occurred");
        console.log("error", response);
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      const initialMenuVisible = {};
      data.data.forEach((cargo) => {
        initialMenuVisible[cargo._id] = false;
      });
      // setMenuVisible(initialMenuVisible);
      setCargos(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cargos:", error);
      setLoading(false);
    }
    setLoading(false);
  };
  let deliveredCargo = cargos.filter(
    (cargo) => cargo.status.toLowerCase() === "delivered"
  );

  const calculateTotalRevenue = (cargos) => {
    return cargos.reduce((acc, cargo) => {
      const amount = parseInt(cargo.amount);
      if (!isNaN(amount)) {
        return acc + amount;
      } else {
        return acc;
      }
    }, 0);
  };
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [reflesh, setReflesh] = useState("true");

  useEffect(() => {
    const calculatedTotalRevenue = calculateTotalRevenue(cargos);
    setTotalRevenue(calculatedTotalRevenue);
  }, [cargos, reflesh]);
  return (
    <div className="admin">
      <main>
        <article className="container article">
          <h2
            className="h2 article-title"
            style={{ textTransform: "capitalize" }}
          >
            Hi {decodedToken?.name}
          </h2>
          <p className="article-subtitle">Welcome to Dashboard!</p>

          <section className="home">
            <div className="card profile-card">
              <button
                className="card-menu-btn icon-box"
                aria-label="More"
                onClick={toggleMenu}
              >
                <i class="bx bx-dots-horizontal-rounded icon"></i>
              </button>

              {menuVisible && (
                <ul className="ctx-menu">
                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn icon-box"
                      onClick={() => {
                        toast.error("Coming Soon. I promise");
                        toggleMenu();
                      }}
                    >
                      <i class="bx bx-edit icon"></i>

                      <span className="ctx-menu-text">Edit</span>
                    </button>
                  </li>

                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn icon-box"
                      onClick={() => {
                        toast.success("Admin Details Refleshed");
                        toggleMenu();
                      }}
                    >
                      <i class="bx bx-refresh icon"></i>

                      <span className="ctx-menu-text">Refresh</span>
                    </button>
                  </li>

                  <li className="divider"></li>

                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn red icon-box"
                      onClick={() => {
                        toast.error("Coming Soon. I promise");
                        toggleMenu();
                      }}
                    >
                      <i class="bx bx-trash icon"></i>

                      <span className="ctx-menu-text">Deactivate</span>
                    </button>
                  </li>
                </ul>
              )}

              <div className="profile-card-wrapper">
                <figure className="card-avatar">
                  <img
                    src="https://res.cloudinary.com/dxxhtz2dw/image/upload/v1711486062/hero1_ymbowl.png"
                    alt="Elizabeth Foster"
                    width="48"
                    height="48"
                  />
                </figure>

                <div>
                  <p className="card-title">{decodedToken?.name}</p>
                  <p className="card-subtitle">
                    Three Dolts - {decodedToken?.role}
                  </p>
                </div>
              </div>

              <ul className="contact-list-2">
                <li>
                  <a
                    href="mailto:xyz@mail.com"
                    className="contact-link-2 icon-box"
                  >
                    <i class="bx bx-envelope icon"></i>
                    <p className="text">{decodedToken?.email}</p>
                  </a>
                </li>
                <li>
                  <a href="tel:00123456789" className="contact-link-2 icon-box">
                    <i class="bx bx-phone icon"></i>
                    <p className="text">0712 012 113</p>
                  </a>
                </li>
              </ul>

              <div className="divider card-divider"></div>

              <ul className="progress-list">
                <li className="progress-item">
                  <div className="progress-label">
                    <p className="progress-title">Competence</p>
                    <data className="progress-data" value="85">
                      85%
                    </data>
                  </div>
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        "--width": "85%",
                        "--bg": "var(--blue-ryb)",
                      }}
                    ></div>
                  </div>
                </li>
                <li className="progress-item">
                  <div className="progress-label">
                    <p className="progress-title">Overall Rating</p>
                    <data className="progress-data" value="7.5">
                      7.5
                    </data>
                  </div>
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        "--width": "75%",
                        "--bg": "var(--coral)",
                      }}
                    ></div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="card-wrapper">
              <div className="card task-card">
                <div className="card-icon icon-box green">
                  <i class="bx bx-task icon"></i>
                </div>
                <div>
                  <data className="card-data" value="21">
                    {loading ? (
                      <div className="card-badge radius-pill loading-badge"></div>
                    ) : (
                      `${deliveredCargo?.length}`
                    )}
                  </data>
                  <p className="card-text">Cargo Completed</p>
                </div>
              </div>

              <div className="card task-card">
                <div className="card-icon icon-box blue">
                  <i class="bx bx-file icon"></i>
                </div>
                <div>
                  <data className="card-data" value="21">
                    {loading ? (
                      <div className="card-badge radius-pill loading-badge"></div>
                    ) : (
                      `${cargos?.length - deliveredCargo?.length}`
                    )}
                  </data>
                  <p className="card-text">Cargo Inprogress</p>
                </div>
              </div>
            </div>

            <div className="card revenue-card">
              <button
                className="card-menu-btn icon-box"
                aria-label="More"
                onClick={toggleMenu2}
              >
                <i class="bx bx-dots-horizontal-rounded icon"></i>
              </button>

              {menuVisible2 && (
                <ul className="ctx-menu">
                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn icon-box"
                      onClick={() => {
                        setReflesh(!reflesh);
                        toast.success("Revenue Reflesh Sucess");
                        toggleMenu2();
                      }}
                    >
                      <i class="bx bx-refresh icon"></i>
                      <span className="ctx-menu-text">Refresh</span>
                    </button>
                  </li>
                </ul>
              )}

              <p className="card-title">Revenue</p>
              <data
                className="card-price"
                value="2100"
                style={{ display: "flex" }}
              >
                <p>sh.</p>
                {loading ? (
                  <div
                    className="card-badge radius-pill loading-badge"
                    style={{
                      width: "50%",
                      borderRadius: "5px",
                      marginLeft: "5px",
                      height: "20px",
                      marginTop: "10px",
                    }}
                  ></div>
                ) : (
                  <p style={{ marginLeft: "5px" }}>
                    {" "}
                    {totalRevenue.toLocaleString()}
                  </p>
                )}
              </data>
              <p className="card-text">Last Month</p>

              <div className="divider card-divider"></div>

              <ul className="revenue-list">
                <li className="revenue-item icon-box">
                  <i class="bx bx-trending-up icon green"></i>

                  <div>
                    <data className="revenue-item-data" value="15">
                      15%
                    </data>
                    <p className="revenue-item-text">Prev Week</p>
                  </div>
                </li>
                <li className="revenue-item icon-box">
                  <i class="bx bx-trending-down icon red"></i>
                  <div>
                    <data className="revenue-item-data" value="10">
                      10%
                    </data>
                    <p className="revenue-item-text">Prev Month</p>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </article>
      </main>
    </div>
  );
};

export default AdminHome;
