import React from "react";
import Header from "./common/Header";
import Hero from "./common/Hero";
import About from "./common/About";
import Services from "./common/Services";
import Security from "./common/Security";
import App from "./common/App";
import Contacts from "./common/Contacts";
import Footer from "./common/Footer";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Services />
      <Security />
      <App />
      <Contacts />
      <Footer />
    </>
  );
};

export default Home;
